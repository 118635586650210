import React from 'react';


export default function Put05(props) {
    
    return (
        <div className={`Put05-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>



<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M249.2,376.5c-2.4,0-4.7,0-6.9,0c-16.9,0-33.8,0.1-50.7,0.1c-15.9,0.1-31.7,0.1-47.6,0.3
			c-1.3,0-1.9-0.4-2.6-1.5c-8.8-14.7-17.6-29.3-26.3-44c-0.6-1-0.9-2.2-0.9-3.3c-0.1-24.9,0-49.8,0-74.7c0-1.9,0-2,1.9-2
			c26,0,52-0.1,78-0.2c2.7,0,4.2,0.8,5.7,3.1c7.5,11.5,15.2,22.9,22.8,34.4c8.7,13,17.3,25.9,26,38.9c0.4,0.6,0.7,1.4,0.7,2
			c0,15.3,0,30.5,0,45.8C249.3,375.6,249.3,375.9,249.2,376.5z"/>
		<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M251.8,376.4c0-0.9,0-1.4,0-2c0-14.5,0-29,0-43.5c0-1.2,0.4-2.6,1.1-3.6c8.3-12.5,16.6-24.9,24.9-37.3
			c8.2-12.3,16.3-24.6,24.4-36.9c0.9-1.3,1.8-1.8,3.4-1.8c12.7,0.1,25.4,0,38,0c13.9,0,27.7,0.1,41.6,0.1c1.4,0,1.8,0.4,1.8,1.8
			c0,24.7,0,49.3-0.1,74c0,1.1-0.4,2.3-0.9,3.2c-8.9,14.8-17.8,29.6-26.6,44.5c-0.5,0.8-1,1.1-1.9,1.1c-4,0-8.1,0-12.1,0
			c-15.9,0.1-31.7,0.1-47.6,0.2c-14.7,0.1-29.3,0.1-44,0.2C253.3,376.4,252.6,376.4,251.8,376.4z"/>
		<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M156,248.9c-13.4,0-26.8,0-40.1,0c-1.4,0-1.8-0.4-1.8-1.8c0-24.3,0-48.6,0.1-72.9c0-1,0.3-2.1,0.8-2.9
			c8.8-14.8,17.8-29.7,26.6-44.5c0.5-0.8,1.1-1.1,2-1.1c17.2,0,34.4,0.1,51.6,0.1c3.4,0,6.8,0.1,10.1,0.1
			c14.1,0.1,28.1,0.1,42.2,0.1c1.3,0,1.8,0.3,1.8,1.7c-0.1,14.2,0,28.4-0.1,42.7c0,0.8-0.3,1.8-0.7,2.5c-7.9,12-15.9,23.9-23.9,35.8
			c-8.6,12.9-17.3,25.8-25.9,38.8c-0.7,1.1-1.4,1.5-2.8,1.5C182.7,248.9,169.4,248.9,156,248.9z"/>
		<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M345.1,248.9c-13.2,0-26.4,0-39.5,0c-1.7,0-2.6-0.6-3.4-1.9c-8.1-12.2-16.2-24.4-24.3-36.6
			c-8.4-12.6-16.8-25.1-25.2-37.6c-0.5-0.8-0.8-1.8-0.8-2.8c-0.1-14.1,0-28.2-0.1-42.4c0-1.2,0.4-1.6,1.6-1.6
			c26,0.1,51.9,0.1,77.9,0.2c8.6,0,17.2,0.1,25.8,0.1c1.3,0,2.1,0.4,2.7,1.6c8.8,14.7,17.6,29.4,26.4,44.1c0.6,1,0.9,2.2,0.9,3.4
			c0.1,23.9,0,47.7,0,71.6c0,1.9,0,1.9-2,1.9C371.8,248.9,358.4,248.9,345.1,248.9z"/>
		<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M319.4,58.6c21.8,0,43.7,0,65.5,0c2.1,0,2.1,0,2.1,2.1c0,4.8,0.1,9.6,0,14.5c0,1.1-0.3,2.4-0.9,3.4
			c-8.7,14.6-17.5,29.2-26.3,43.9c-0.7,1.2-1.5,1.6-2.8,1.6c-21.5-0.1-43-0.1-64.4-0.2c-11.3,0-22.6-0.1-33.9-0.2
			c-1.9,0-3.9-0.1-5.8-0.1c-0.8,0-1.2-0.3-1.2-1.1c0-0.3,0-0.6,0-0.9c0-20.3,0-40.6,0-60.8c0-2.1,0-2.1,2-2.1
			C275.7,58.6,297.5,58.6,319.4,58.6z"/>
		<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M181.8,58.6c21.8,0,43.7,0,65.5,0c2,0,2,0,2,2c0,20.3,0,40.7,0,61c0,1.9,0,1.9-1.9,1.9
			c-14.4,0-28.7,0-43.1-0.1c-9,0-17.9-0.1-26.9-0.1c-9.2,0-18.4-0.1-27.6-0.1c-2,0-4-0.2-6-0.1c-1.2,0-1.8-0.4-2.4-1.4
			c-8.5-14.2-17-28.4-25.6-42.5c-1.3-2.1-2-4.2-1.8-6.7c0.2-4,0.1-8,0.1-11.9c0-2,0-2,2-2C138,58.6,159.9,58.6,181.8,58.6z"/>
		<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M319.4,441.5c-21.8,0-43.7,0-65.5,0c-2.1,0-2.1,0-2.1-2.1c0-19.5,0-39.1,0-58.6c0-2.1,0-2.1,2-2.1
			c16.6,0,33.1,0,49.7-0.1c9.1,0,18.2,0,27.3-0.1c8.7,0,17.4-0.1,26.1-0.2c1.4,0,2.2,0.4,3,1.6c8.7,14.6,17.5,29.2,26.3,43.9
			c0.5,0.9,0.8,2,0.8,3.1c0.1,4.2,0,8.5,0.1,12.7c0,1.5-0.4,1.9-1.9,1.9C363.2,441.5,341.3,441.5,319.4,441.5z"/>
		<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M181.8,441.5c-21.8,0-43.6,0-65.4,0c-2.2,0-2.2,0-2.2-2.2c0-3.9-0.1-7.9,0-11.8c0-0.8,0.2-1.7,0.6-2.4
			c9-15,18-30,27-45c0.3-0.5,1.1-0.7,1.7-1.1c0.2-0.1,0.4,0,0.6,0c16.8,0,33.6-0.1,50.4-0.1c9.2,0,18.3,0,27.5-0.1
			c8.5,0,17-0.1,25.5-0.2c1.4,0,1.9,0.3,1.9,1.8c0,19.7-0.1,39.4,0,59.1c0,1.6-0.5,1.9-2,1.9C225.5,441.5,203.6,441.5,181.8,441.5z"
			/>
		<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M65.3,313.9c0-20.2,0-40.5,0-60.7c0-2,0-2,1.9-2c9.3,0,18.5,0,27.8,0c5.1,0,10.2,0.1,15.4,0.1
			c1.1,0,1.5,0.3,1.5,1.4c0,25.3,0,50.5-0.1,75.8c0,0.8-0.3,1.8-0.7,2.5c-8.9,14.9-17.8,29.7-26.7,44.6c-0.4,0.8-0.9,1.2-1.9,1.1
			c-5.3-0.1-10.6-0.2-16-0.2c-1.2,0-1.2-0.7-1.2-1.5c0-6.4,0-12.7,0-19.1C65.3,342,65.3,328,65.3,313.9z"/>
		<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M435.9,376.3c-3.5,0-6.7,0-9.9,0c-2.3,0-4.7-0.2-7-0.2c-1,0-1.6-0.3-2.2-1.2c-8.9-14.9-17.8-29.7-26.7-44.6
			c-0.5-0.8-0.7-1.8-0.7-2.6c0-24.9,0-49.7,0-74.6c0-0.4,0-0.9,0.1-1.5c0.5,0,1-0.1,1.5-0.1c14.3-0.1,28.6-0.1,43-0.1c2,0,2,0,2,1.9
			c0,40.5,0,80.9,0,121.4C435.9,375.1,435.9,375.7,435.9,376.3z"/>
		<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M65.3,248.8c0-0.7,0-1.2,0-1.8c0-39.8,0-79.5,0-119.3c0-2,0-2,1.9-2c5,0,9.9-0.1,14.9-0.2
			c1.1,0,1.7,0.3,2.3,1.2c8.8,14.8,17.7,29.6,26.5,44.4c0.5,0.8,0.8,1.9,0.8,2.8c0,24.4,0,48.8,0.1,73.2c0,1.1-0.1,1.7-1.5,1.7
			c-14.6,0-29.1,0-43.7,0C66.2,248.9,65.9,248.8,65.3,248.8z"/>
		<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M389.4,248.8c0-0.6,0-1.2,0-1.8c0-24,0-48,0-72c0-1,0.3-2.1,0.8-2.9c8.8-14.8,17.7-29.5,26.5-44.2
			c0.7-1.1,1.4-1.4,2.6-1.5c5-0.1,9.9-0.2,14.9-0.4c1.3-0.1,1.7,0.4,1.7,1.7c0,39.9,0,79.7,0,119.6c0,1,0,1.7-1.4,1.7
			c-14.7,0-29.3,0-44,0C390.2,248.9,389.9,248.8,389.4,248.8z"/>
		<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M438.3,346.3c0-31,0-61.9,0-92.9c0-2.1,0-2.1,2.1-2.1c7.3,0,14.5,0,21.8,0c1.3,0,1.7,0.4,1.7,1.7
			c0,23.2,0,46.4-0.1,69.6c0,0.8,0,1.7,0,2.5c0,38.1,0,76.2,0,114.2c0,2.2,0,2.2-2.2,2.2c-7.2,0-14.4,0-21.6,0
			c-1.4,0-1.7-0.4-1.7-1.8C438.3,408.6,438.3,377.4,438.3,346.3z"/>
		<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M463.8,248.8c-8.6,0-17,0-25.5,0c0-0.6-0.1-1.1-0.1-1.7c0-62.2,0-124.4,0-186.6c0-1.4,0.3-1.9,1.8-1.9
			c7.4,0.1,14.7,0.1,22.1,0c1.3,0,1.7,0.3,1.7,1.7c0,39,0,78-0.1,116.9c0,23.2,0,46.3,0,69.5C463.8,247.4,463.8,248,463.8,248.8z"/>
		<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M37.4,346.5c0-31,0-62,0-93.1c0-2.1,0-2.1,2.1-2.1c7.2,0,14.4,0,21.6,0c1.5,0,1.8,0.5,1.8,1.9
			c0,40.9,0,81.8,0,122.7c0,21.2,0,42.4,0,63.5c0,2.1,0,2.1-2.1,2.1c-7.2,0-14.3,0-21.5,0c-1.9,0-1.9,0-1.9-2c0-17.7,0-35.4,0-53.1
			C37.4,373.1,37.4,359.8,37.4,346.5z"/>
		<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M37.5,153.7c0-12.5,0-25.1,0-37.6c0-18.4,0-36.9-0.1-55.3c0-2.1,0-2.1,2.1-2.1c7.2,0,14.4,0,21.6,0
			c1.4,0,1.9,0.4,1.9,1.9c0,20.6,0,41.3,0,61.9c0,41.2,0,82.4,0,123.6c0,0.4,0,0.9,0,1.3c0.1,1.2-0.3,1.5-1.5,1.5
			c-6.7,0-13.4,0-20.1,0c-3.9,0-3.9,0-3.9-3.9c0-21.3,0-42.6,0-63.8C37.4,172,37.4,162.8,37.5,153.7
			C37.4,153.7,37.4,153.7,37.5,153.7z"/>
		<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M2.5,383c0-0.7,0-1.3,0-1.9c0-42.6,0-85.2,0-127.8c0-2,0-2,2-2c9.5,0,19.1,0,28.6,0c1.4,0,1.8,0.4,1.8,1.8
			c0,22.7,0,45.3,0,68c0,20,0,40,0,60c0,0.6,0,1.3,0,2C24.1,383,13.5,383,2.5,383z"/>
		<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M34.9,183c0,21.3,0,42.5,0,63.8c0,2,0,2-2,2c-9.6,0-19.2,0-28.8,0c-1.2,0-1.6-0.3-1.6-1.6
			c0-42.8,0-85.7,0-128.5c0-1.3,0.4-1.6,1.6-1.5c9.7,0,19.5,0,29.2,0c1.2,0,1.6,0.3,1.6,1.6c-0.1,19.5-0.1,39.1-0.1,58.6
			C34.9,179.2,34.9,181.1,34.9,183C34.9,183,34.9,183,34.9,183z"/>
		<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M435.9,56.3c-0.7,0-1.3,0.1-1.9,0.1c-60.1,0-120.3,0-180.4,0c-1.5,0-1.9-0.4-1.9-1.9c0.1-6.4,0.1-12.7,0-19.1
			c0-1.4,0.4-1.8,1.9-1.8c22.2,0,44.4,0,66.5,0c20.6,0,41.3,0,61.9,0c17.3,0,34.5,0,51.8,0c2.1,0,2.1,0,2.1,2.1c0,6.2,0,12.4,0,18.6
			C435.9,54.9,435.9,55.5,435.9,56.3z"/>
		<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M65.4,56.2c0-0.5-0.1-0.9-0.1-1.3c0-6.6,0-13.1,0-19.7c0-1.3,0.3-1.7,1.7-1.7c16.1,0.1,32.2,0.1,48.3,0.1
			c12.8,0,25.7-0.1,38.5-0.1c31.2,0,62.4,0,93.5,0c2,0,2,0,2,2c0,6.3,0,12.6,0,18.9c0,0.5,0,1.1,0,1.7
			C188,56.2,126.7,56.2,65.4,56.2z"/>
		<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M251.9,444c0.7,0,1.3,0,1.9,0c60.1,0,120.1,0,180.2,0c1.9,0,1.9,0,1.9,1.9c0,6.3-0.1,12.6,0,18.9
			c0,1.6-0.5,1.9-2,1.9c-15.1-0.1-30.2,0-45.4,0c-20.9,0-41.9,0-62.8,0c-24,0-48,0-72.1,0c-2,0-2,0-2-2.1c0-6.3,0-12.5,0-18.8
			C251.8,445.3,251.8,444.7,251.9,444z"/>
		<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M65.4,466.7c0-7.6,0-15.1,0-22.7c61.3,0,122.5,0,183.8,0c0,0.5,0.1,0.9,0.1,1.4c0,6.6,0,13.1,0,19.7
			c0,1.4-0.4,1.6-1.7,1.6c-22.3,0-44.6,0-66.8,0c-20.7,0-41.5,0-62.2,0c-17.1,0-34.1,0-51.2,0C66.8,466.7,66.1,466.7,65.4,466.7z"/>
		<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M497.5,317.3c0,21.3,0,42.6,0,63.8c0,2,0,2-2,2c-9.1,0-18.1,0-27.2,0c-2,0-2,0-2-2c0-18.5,0-37.1,0-55.6
			c0-24,0-47.9,0-71.9c0-2.3,0-2.3,2.3-2.3c9,0,18,0,27,0c1.5,0,1.9,0.4,1.9,1.9C497.5,274.5,497.5,295.9,497.5,317.3z"/>
		<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M497.5,117.2c0,0.8,0,1.4,0,1.9c0,42.6,0,85.1,0,127.7c0,2,0,2-1.9,2c-9.2,0-18.4,0-27.6,0
			c-1.4,0-1.7-0.4-1.7-1.8c0-22.5,0-45,0-67.6c0-20.1,0-40.2,0-60.3c0-2,0-2,2-2c9.1,0,18.2,0,27.3,0
			C496.1,117.2,496.7,117.2,497.5,117.2z"/>
		<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M249.2,31.3c-0.6,0-1.2,0-1.8,0c-43,0-85.9,0-128.9,0c-2,0-2,0-2-2.1c0-8.4,0-16.7,0-25.1
			c0-1.3,0.4-1.7,1.7-1.7c43.2,0,86.4,0,129.7,0c0.8,0,1.5-0.1,1.5,1.2c0,8.9,0,17.8,0,26.7C249.3,30.6,249.3,30.9,249.2,31.3z"/>
		<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M384.5,31.3c-0.7,0-1.2,0-1.8,0c-43,0-86,0-129.1,0c-1.5,0-1.9-0.4-1.9-1.9c0.1-8.4,0.1-16.8,0-25.2
			c0-1.3,0.4-1.7,1.7-1.7c43.2,0,86.4,0,129.7,0c1.1,0,1.6,0.2,1.6,1.5c-0.1,8.7,0,17.3,0,26C384.6,30.3,384.6,30.7,384.5,31.3z"/>
		<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M249.3,469.1c0,0.7,0,1.2,0,1.7c0,8.4,0,16.7,0,25.1c0,1.4-0.5,1.7-1.8,1.7c-43.1,0-86.2,0-129.2,0
			c-1.4,0-1.9-0.3-1.9-1.8c0.1-8.4,0.1-16.7,0-25.1c0-1.2,0.4-1.6,1.6-1.6c43.3,0,86.6,0,129.8,0
			C248.3,469.1,248.7,469.1,249.3,469.1z"/>
		<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M251.8,469.1c0.8,0,1.4,0,2,0c43,0,85.9,0,128.9,0c2,0,2,0,2,1.9c0,8.3,0,16.5,0,24.8c0,1.4-0.4,1.8-1.8,1.8
			c-43.1,0-86.2,0-129.3,0c-1.4,0-1.9-0.4-1.8-1.9c0.1-8.3,0-16.6,0-24.9C251.8,470.3,251.8,469.8,251.8,469.1z"/>
		<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M2.6,4.2c1.1,1,2,1.7,2.8,2.4c9.5,8.4,18.9,16.9,28.3,25.4c0.6,0.5,1.1,1.6,1.1,2.4c0.1,26.3,0,52.6,0.1,78.9
			c0,1.2-0.4,1.6-1.6,1.6c-9.7,0-19.5,0-29.2,0c-1.2,0-1.6-0.3-1.6-1.6c0-35.9,0-71.7,0-107.6C2.5,5.4,2.6,5,2.6,4.2z"/>
		<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M2.5,495.9c0-1,0-1.5,0-2.1c0-35.4,0-70.9,0-106.3c0-2,0-2.1,2-2.1c9.4,0,18.9,0,28.3,0c2.1,0,2.1,0,2.1,2
			c0,18.4,0,36.9,0,55.3c0,7.7,0,15.4,0.1,23.1c0,1-0.3,1.7-1.1,2.4c-10,8.8-19.9,17.6-29.8,26.4C3.7,495,3.3,495.3,2.5,495.9z"/>
		<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M497.4,4.4c0,0.8,0.1,1.3,0.1,1.9c0,35.6,0,71.2,0,106.8c0,1.4-0.3,1.9-1.8,1.9c-9.3-0.1-18.5-0.1-27.8,0
			c-1.4,0-1.7-0.5-1.7-1.8c0-17.9,0-35.8,0-53.7c0-8.3,0-16.6,0-24.9c0-0.8,0.4-1.9,1-2.5c9.7-9.1,19.5-18,29.2-27
			C496.7,4.9,496.9,4.7,497.4,4.4z"/>
		<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M497.1,495.5c-0.5-0.5-1.1-0.9-1.6-1.4c-9.3-8.5-18.6-17-28-25.5c-0.9-0.8-1.3-1.7-1.3-3c0-26,0-52.1,0-78.1
			c0-2,0-2,1.9-2c9.1,0,18.3,0,27.4,0c1.6,0,1.9,0.5,1.9,2c0,35.5,0,71,0,106.5c0,0.5,0,1,0,1.5
			C497.3,495.4,497.2,495.4,497.1,495.5z"/>
		<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M114,31.3c-0.7,0-1.2,0-1.8,0c-24.7,0-49.3,0-74,0c-1.2,0-2-0.3-2.9-1.1C25.6,21.4,15.9,12.7,6.1,4
			C5.7,3.7,5.3,3.3,4.7,2.7c36.6,0,72.9,0,109.3,0C114,12.2,114,21.6,114,31.3z"/>
		<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M387.2,2.6c36.1,0,71.9,0,108.1,0c-0.4,0.5-0.7,0.8-1,1.1c-9.5,8.8-19,17.5-28.5,26.3
			c-0.9,0.9-1.9,1.3-3.2,1.3c-24.5,0-49,0-73.5,0c-0.6,0-1.3,0-2,0C387.2,21.6,387.2,12.2,387.2,2.6z"/>
		<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M114.1,469.1c0,0.6,0,1.1,0,1.5c0,8.4,0,16.8,0,25.2c0,1.2-0.2,1.7-1.5,1.7c-35.4,0-70.8,0-106.2,0
			c-0.4,0-0.8-0.1-1.3-0.1c-0.1-0.2-0.3-0.4-0.4-0.5c0.4-0.2,0.9-0.4,1.3-0.7c9.8-8.7,19.7-17.4,29.5-26c0.6-0.5,1.6-1,2.3-1
			c24.9,0,49.8,0,74.7,0C113,469.1,113.5,469.1,114.1,469.1z"/>
		<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M494.9,497.5c-0.5,0-1.1,0-1.6,0c-34.7,0-69.4,0-104.2,0c-2.1,0-2.1,0-2.1-2c0-8.2,0-16.3,0-24.5
			c0-0.6,0-1.2,0-1.9c0.7,0,1.2,0,1.8,0c24.7,0,49.4,0,74.2,0c1.1,0,1.9,0.3,2.6,1c9.4,8.6,18.8,17.3,28.3,25.9
			c0.4,0.4,0.8,0.7,1.1,1.1C495,497.2,495,497.3,494.9,497.5z"/>
		<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M113,332.7c1.8,3.1,3.6,5.9,5.3,8.8c7.1,11.8,14.1,23.5,21.2,35.2c0.5,0.8,0.7,1.5,0.1,2.4
			c-8.7,14.4-17.3,28.8-25.9,43.2c-0.2,0.3-0.4,0.6-0.7,1c-0.3-0.4-0.6-0.7-0.8-1.1c-8.6-14.3-17.2-28.6-25.8-42.9
			c-0.6-1-0.6-1.7,0-2.7c8.6-14.2,17.1-28.5,25.7-42.8C112.4,333.5,112.6,333.2,113,332.7z"/>
		<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M112.9,169.8c-1.1-1.8-2.1-3.5-3.1-5.1c-7.8-12.9-15.5-25.9-23.3-38.8c-0.6-1-0.7-1.8-0.1-2.8
			c8.5-14.1,16.9-28.2,25.4-42.3c0.3-0.5,0.7-1.1,1.2-1.8c2.1,3.6,4.2,6.9,6.2,10.3c6.8,11.3,13.5,22.6,20.3,33.8
			c0.5,0.8,0.7,1.5,0.1,2.4c-8.7,14.4-17.3,28.9-26,43.3C113.5,169,113.3,169.3,112.9,169.8z"/>
		<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M388.2,170.7c-1.9-3.1-3.6-5.9-5.3-8.8c-7-11.7-14-23.4-21.1-35.1c-0.7-1.1-0.7-1.9,0-3
			c8.5-14.2,17-28.3,25.6-42.5c0.2-0.4,0.5-0.8,0.9-1.4c1.7,2.9,3.4,5.6,5,8.3c7.1,11.8,14.2,23.7,21.3,35.5c0.7,1.1,0.7,1.9,0,3.1
			c-8.6,14.1-17.1,28.3-25.6,42.5C388.8,169.7,388.6,170,388.2,170.7z"/>
		<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M388.3,331.8c2.6,4.3,5.1,8.5,7.6,12.6c6.3,10.5,12.6,21,18.9,31.4c0.5,0.9,0.7,1.6,0.1,2.6
			c-8.7,14.4-17.3,28.8-25.9,43.2c-0.2,0.3-0.3,0.5-0.7,0.9c-0.3-0.5-0.7-0.8-0.9-1.2c-8.6-14.3-17.2-28.6-25.8-42.9
			c-0.5-0.8-0.6-1.4-0.1-2.2c8.7-14.4,17.3-28.9,26-43.3C387.7,332.5,387.9,332.3,388.3,331.8z"/>
		<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M435.9,91.1c0,10.2,0,20.4,0,30.6c0,1.4-0.4,1.9-1.8,1.9c-5,0.1-9.9,0.2-14.9,0.4c-1.2,0-1.9-0.3-2.5-1.4
			c-8.8-14.8-17.7-29.5-26.5-44.2c-0.5-0.8-0.8-1.8-0.8-2.8c-0.1-5.1,0-10.2-0.1-15.4c0-1.3,0.4-1.7,1.7-1.7c14.4,0,28.8,0,43.1,0
			c1.4,0,1.7,0.5,1.7,1.8C435.9,70.7,435.9,80.9,435.9,91.1z"/>
		<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M65.3,123.4c0-0.7,0-1.3,0-1.8c0-20.3,0-40.5,0-60.8c0-2.1,0-2.1,2.1-2.1c14.2,0,28.3,0,42.5,0
			c1.5,0,2,0.3,2,1.9c-0.1,4.7,0,9.4-0.1,14.2c0,0.9-0.3,2-0.8,2.8c-8.9,14.8-17.8,29.6-26.6,44.5c-0.7,1.2-1.7,1.3-2.9,1.3
			c-3.9,0.1-7.7,0.1-11.6,0.1C68.4,123.4,67,123.4,65.3,123.4z"/>
		<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M435.9,410.3c0,9.7,0,19.5,0,29.2c0,2,0,2-1.9,2c-14.3,0-28.5,0-42.8,0c-1.5,0-1.8-0.5-1.8-1.9
			c0.1-4.3,0-8.6,0.1-13c0-0.9,0.3-1.9,0.7-2.6c8.9-14.8,17.8-29.6,26.6-44.5c0.5-0.8,1-1.2,2-1.2c5.3,0.2,10.5,0.3,15.8,0.5
			c1.3,0,1.4,0.8,1.4,1.8C435.9,390.5,435.9,400.4,435.9,410.3z"/>
		<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M65.4,379.1c3.5,0,6.9,0,10.3,0c2.1,0,4.3,0.1,6.4,0.1c0.9,0,1.5,0.2,2.1,1.1c8.9,15,17.9,29.9,26.9,44.9
			c0.4,0.6,0.6,1.4,0.6,2.1c0.1,4.3,0,8.6,0.1,13c0,1-0.3,1.4-1.4,1.4c-14.7,0-29.4,0-44.2,0c-0.2,0-0.5-0.1-0.9-0.1
			C65.4,420.7,65.4,399.9,65.4,379.1z"/>
		<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M224.5,213.3c0.4,0.6,0.7,1,1,1.4c7.6,11.4,15.1,22.7,22.7,34.1c0.7,1,0.8,1.7,0.1,2.8
			c-7.6,11.3-15.2,22.7-22.8,34.1c-0.3,0.4-0.6,0.9-1.1,1.4c-0.5-0.8-1-1.4-1.4-2c-7.5-11.2-14.9-22.4-22.4-33.6
			c-0.7-1-0.7-1.7,0-2.8c7.6-11.4,15.2-22.8,22.8-34.2C223.8,214.2,224.1,213.8,224.5,213.3z"/>
		<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M276.7,287c-0.4-0.6-0.7-0.9-1-1.3c-7.6-11.4-15.1-22.7-22.8-34.1c-0.7-1.1-0.7-1.8,0-2.9
			c7.6-11.3,15.1-22.6,22.7-33.9c0.3-0.4,0.6-0.9,1-1.4c0.4,0.6,0.8,1,1.1,1.4c7.6,11.3,15.1,22.6,22.7,33.9c0.7,1,0.8,1.7,0.1,2.7
			c-7.7,11.4-15.3,22.9-22.9,34.3C277.4,286.1,277.1,286.4,276.7,287z"/>
		<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M275.2,289.3c-8.2,12.3-16.3,24.5-24.6,36.9c-1.6-2.4-3.1-4.5-4.5-6.7c-6.5-9.7-13-19.5-19.5-29.2
			c-0.5-0.7-0.6-1.2-0.1-2c7.8-11.6,15.6-23.3,23.3-34.9c0.2-0.2,0.4-0.5,0.8-1C258.9,264.8,267.1,277,275.2,289.3z"/>
		<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M250.6,247.9c-3.6-5.4-7-10.5-10.4-15.6c-4.5-6.7-9-13.5-13.5-20.2c-0.5-0.7-0.6-1.2-0.1-2
			c7.8-11.6,15.6-23.3,23.4-34.9c0.2-0.3,0.4-0.5,0.7-0.9c0.3,0.4,0.6,0.8,0.9,1.1c7.6,11.4,15.3,22.9,22.9,34.3
			c0.7,1,0.7,1.6,0,2.6c-7.6,11.4-15.2,22.8-22.8,34.2C251.3,246.9,251,247.3,250.6,247.9z"/>
		<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M438.3,56.2c0-0.5-0.1-0.8-0.1-1.2c0-6.6,0-13.2,0-19.8c0-1.1,0.3-1.6,1.5-1.6c7.6,0,15.1,0,22.7,0
			c1.2,0,1.4,0.4,1.4,1.5c0,6.7,0,13.3,0,20c0,0.9-0.1,1.4-1.2,1.4c-7.8,0-15.5,0-23.3,0C439,56.3,438.7,56.2,438.3,56.2z"/>
		<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M50.1,56.4c-3.8,0-7.6,0-11.3,0c-1,0-1.4-0.3-1.4-1.4c0-6.7,0-13.3,0-20c0-1.1,0.3-1.5,1.4-1.5
			c7.6,0,15.1,0,22.7,0c1.1,0,1.5,0.4,1.5,1.5c0,6.7,0,13.3,0,20c0,1.1-0.4,1.4-1.4,1.4C57.7,56.3,53.9,56.4,50.1,56.4z"/>
		<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M438.3,444.1c8.5,0,16.9,0,25.3,0c0,7.6,0,15,0,22.6c-0.6,0-1,0.1-1.4,0.1c-7.5,0-14.9,0-22.4,0
			c-1.3,0-1.7-0.4-1.7-1.7c0.1-6.5,0-13,0-19.5C438.3,445.1,438.3,444.7,438.3,444.1z"/>
		<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M37.6,444c1.6,0,3.2,0,4.7,0c6.4,0,12.7,0,19.1,0c1.2,0,1.6,0.3,1.6,1.6c-0.1,6.5-0.1,13,0,19.5
			c0,1.3-0.4,1.7-1.7,1.7c-7.5-0.1-14.9,0-22.4,0c-0.9,0-1.5,0-1.5-1.2c0-6.9,0-13.8,0-20.7C37.4,444.6,37.5,444.4,37.6,444z"/>




</g>
            </svg>
        </div>
    )
}