import React from 'react';


export default function Put04(props) {
    
    return (
        <div className={`Put04-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>


<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M248.4,165c0,16.8,0,33.6,0,50.5c0,0.9-0.2,1.9-0.6,2.7c-2.1,4.3-4,8.6-6.4,12.7c-4,7-10.5,11-17.6,14.5
		c-5.2,2.5-10.4,3.4-16.1,3.4c-31-0.2-62.1-0.1-93.1-0.1c-2.3,0-2.3,0-2.3-2.2c0-32.5,0-65.1,0-97.6c0-2.7,0.3-5.1,1.6-7.4
		c2.1-3.7,3.8-7.7,6-11.4c3.4-5.8,8.6-9.7,14.6-12.7c1.2-0.6,2.5-1.1,3.6-1.8c4.3-2.6,9-3.2,14-3.2c31.3,0.2,62.7,0.1,94,0.1
		c2.2,0,2.2,0,2.2,2.2C248.3,131.3,248.3,148.1,248.4,165C248.3,165,248.4,165,248.4,165z"/>
	<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M112.3,304.3c0-16.8,0-33.5,0-50.3c0-1.4,0.3-1.9,1.8-1.9c33.5,0,67,0,100.5,0c0.8,0,1.8,0,2.5,0.4
		c4.6,2.4,9.3,4.6,13.7,7.3c6.4,3.9,10.6,9.7,13.7,16.4c1.1,2.4,2.3,4.7,3.4,7.2c0.4,0.8,0.6,1.7,0.6,2.6c0,27.3,0,54.6,0,81.9
		c0,6.2-0.1,12.4,0,18.6c0,1.5-0.3,2-1.9,2c-33.4,0-66.8,0-100.2-0.1c-0.7,0-1.5-0.1-2.2-0.4c-4.6-2.2-9.2-4.3-13.6-6.8
		c-7-4-11.4-10.4-14.6-17.7c-0.3-0.6-0.5-1.2-0.9-1.7c-2.3-3.8-2.8-7.9-2.8-12.3C112.4,334.5,112.3,319.4,112.3,304.3z"/>
	<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M387.2,248.3c-0.8,0-1.4,0-2,0c-28.2,0-56.4,0.1-84.6,0.1c-4.2,0-8.4,0-12.6,0.2c-2.6,0.1-5-0.3-7.3-1.5
		c-3.3-1.7-6.8-3.1-10.1-4.9c-6.9-3.8-11.5-9.7-14.7-16.8c-0.2-0.5-0.5-1.1-0.8-1.6c-2.7-4.4-3.4-9.2-3.3-14.4
		c0.2-25.3,0.1-50.7,0-76c0-6.4,0-12.8-0.1-19.2c0-1.4,0.3-1.9,1.8-1.9c33.2,0,66.4,0,99.5,0c0.8,0,1.6,0.1,2.3,0.4
		c4.5,2.2,9.1,4.3,13.5,6.8c6.9,4,11.3,10.4,14.5,17.6c0.3,0.7,0.6,1.4,1,2c2.3,3.7,2.6,7.7,2.6,12c-0.1,31.7,0,63.4,0.1,95
		C387.2,246.8,387.2,247.4,387.2,248.3z"/>
	<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M387.2,251.9c0,0.8,0,1.4,0,2c0,29.8-0.1,59.7-0.1,89.5c0,3.7,0,7.4-0.1,11.1c0,0.8,0,1.7-0.4,2.3
		c-2.3,4.6-4.4,9.3-7,13.7c-3.8,6.5-9.6,10.7-16.3,13.8c-0.5,0.3-1.1,0.5-1.6,0.8c-4.4,2.7-9.2,3.4-14.4,3.4
		c-31.1-0.2-62.3-0.1-93.4-0.1c-2,0-2,0-2-2c0-33.4,0-66.8,0.1-100.2c0-1.4,0.7-2.9,1.3-4.2c2-4,3.9-8.1,6.2-11.9
		c3.4-5.7,8.5-9.8,14.3-12.8c2.9-1.6,5.9-3,9-4.4c0.9-0.4,2-0.7,3-0.7c14.5-0.1,28.9-0.1,43.4-0.2c18.6,0,37.2-0.1,55.8-0.1
		C385.7,251.9,386.4,251.9,387.2,251.9z"/>
	<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M457,320.1c0,1.4,0,2.8,0,4.2c0,20.7,0,41.3,0,62c0,2.1,0,2.1-2,2.1c-10.1,0-20.3,0.1-30.4,0.1
		c-1.1,0-2.4-0.3-3.4-0.8c-3.4-1.5-6.7-3.1-10-4.8c-7.9-4.1-13.2-10.5-16.8-18.4c-0.8-1.7-1.5-3.4-2.4-5c-1.2-2.1-1.5-4.2-1.5-6.6
		c0.1-33.1,0.1-66.1,0.2-99.2c0-1.3,0.3-1.7,1.7-1.7c21,0,41.9,0,62.9-0.1c1.4,0,1.8,0.4,1.8,1.8c0,21.4,0,42.7,0,64.1
		C457,318.5,457,319.3,457,320.1z"/>
	<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M390.8,248.3c0-0.7,0-1.2,0-1.7c0-15.2,0-30.4-0.1-45.6c0-14.5,0-29,0-43.6c0-2.7,0-5.5-0.1-8.2
		c-0.1-3.1,0.3-5.9,1.8-8.6c2.3-4,4.1-8.2,6.6-12c3.7-5.7,9.2-9.4,15.4-12.2c0.5-0.3,1.1-0.4,1.6-0.8c5.3-3.5,11.1-3.5,17.1-3.2
		c4.4,0.2,8.8,0,13.2,0.1c3.1,0,6.3,0.1,9.4,0.1c1.1,0,1.4,0.4,1.3,1.4c0,2.7,0,5.4,0,8.1c0,18.1,0,36.1,0,54.2
		c0,23.3,0,46.6,0,69.9c0,2.1,0,2.1-2.1,2.1c-8.2,0-16.4,0-24.5,0c-12.5,0-25,0-37.6,0.1C392.2,248.3,391.6,248.3,390.8,248.3z"/>
	<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M180.5,43c22.1,0,44.1,0,66.2,0c1.2,0,1.6,0.3,1.6,1.6c-0.1,7.4,0,14.8,0,22.2c0,13.4,0,26.7,0.1,40.1
		c0,1.4-0.3,2-1.9,2c-33.4-0.1-66.9,0-100.3-0.1c-1.1,0-2.4-0.3-3.4-0.8c-4.8-2.4-9.7-4.7-14.2-7.6c-6-3.8-9.7-9.7-12.5-16
		c-0.3-0.7-0.5-1.4-1-2c-3.1-4.5-2.7-9.5-2.7-14.5c0.1-7.7,0.2-15.5,0.2-23.2c0-1.2,0.4-1.6,1.6-1.6c21.4,0,42.8,0,64.2,0
		C179.1,43,179.8,43,180.5,43C180.5,43,180.5,43,180.5,43z"/>
	<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M43,320.3c0-14.8,0-29.5,0-44.3c0-7.4,0-14.8,0-22.2c0-1.3,0.4-1.7,1.7-1.7c20.8,0,41.5,0,62.3,0
		c1.4,0,1.8,0.4,1.8,1.8c0,33.6,0,67.2-0.1,100.7c0,1.1-0.3,2.3-0.8,3.3c-2,4.2-4,8.5-6.4,12.5c-3.4,5.9-8.6,10-14.7,13
		c-2.8,1.4-5.7,2.8-8.7,4.1c-1,0.4-2.1,0.7-3.2,0.7c-10.1,0.1-20.3,0-30.4,0c-1,0-1.7,0-1.7-1.4C43,364.7,43,342.5,43,320.3z"/>
	<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M43,180.6c0-21.9,0-43.9,0-65.8c0-2.3,0-2.3,2.4-2.3c9.6,0,19.2-0.1,28.7,0c1.6,0,3.4,0.4,4.9,1.1
		c3.8,1.7,7.5,3.5,11.1,5.5c7.1,4.1,11.8,10.3,15.1,17.7c0.4,0.8,0.7,1.7,1.2,2.4c2.2,3.5,2.5,7.4,2.5,11.4c-0.1,32-0.1,64-0.1,95.9
		c0,2,0,2-2.1,2c-20.6,0-41.1,0-61.7,0c-2,0-2,0-2-2c0-21.3,0-42.6,0-63.9C43,182,43,181.3,43,180.6C43,180.6,43,180.6,43,180.6z"/>
	<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M319.6,43c22,0,44,0,66,0c1.1,0,1.9,0,1.8,1.5c-0.2,10.2-0.2,20.4-0.4,30.5c0,1-0.3,2.1-0.7,3
		c-2.5,5.2-4.6,10.6-8.1,15.3c-4.2,5.6-10,9.1-16.2,12.2c-4.8,2.5-9.7,3.3-15,3.3c-31-0.2-62-0.1-93-0.1c-2.1,0-2.1,0-2.1-2.1
		c0-20.6,0-41.1-0.1-61.7c0-2,0-2,2-2c21.1,0,42.1,0,63.2,0C317.9,43,318.8,43,319.6,43z"/>
	<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M180.4,457c-21.9,0-43.8,0-65.7,0c-2.1,0-2.1,0-2.1-2.1c-0.1-8.8-0.1-17.6-0.3-26.3c-0.1-2.9,0.3-5.5,1.7-8
		c2.4-4.2,4.3-8.6,6.9-12.6c4-6.1,10.1-9.5,16.5-12.7c4.8-2.4,9.6-3.3,14.9-3.3c31.3,0.2,62.6,0.1,93.9,0.1c2.2,0,2.2,0,2.2,2.2
		c0,20.3,0,40.5-0.1,60.8c0,2,0,2-2.1,2c-21.2,0-42.3,0-63.5,0C181.9,457,181.2,457,180.4,457z"/>
	<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M319.5,457c-22,0-44,0-66,0c-1.3,0-1.7-0.4-1.7-1.7c0.1-20.6,0.1-41.1,0-61.7c0-1.4,0.4-1.7,1.7-1.7
		c33.2,0,66.5,0,99.7,0c0.7,0,1.5-0.1,2.1,0.2c4.7,2.3,9.6,4.5,14.1,7.2c5.9,3.5,10,8.8,13,14.9c1.2,2.5,2.5,5,3.5,7.6
		c0.6,1.6,1.1,3.3,1.1,5c0.2,9.4,0.2,18.9,0.4,28.3c0,1.4-0.4,1.8-1.8,1.8c-21.4,0-42.8,0-64.2,0C320.8,457,320.2,457,319.5,457z"/>
	<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M249.9,2.5c75,0,150,0,225.1,0c2.3,0,2.3,0,2.3,2.3c0,4.2,0,8.4,0,12.6c0,1.2-0.3,1.8-1.7,1.8
		c-51.5,0-103,0-154.5,0c-14.7,0-29.3,0-44,0c-77.7,0-155.3,0-233,0c-6.6,0-13.3,0-19.9,0c-1.2,0-1.6-0.4-1.6-1.5
		c0-4.6,0-9.2,0-13.8c0-1,0.3-1.5,1.4-1.4c0.5,0.1,1,0,1.5,0C100.3,2.5,175.1,2.5,249.9,2.5z"/>
	<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M497.5,250.1c0,74.7,0,149.5,0,224.2c0,0.5,0,1.1,0,1.6c0,0.9-0.3,1.3-1.2,1.3c-4.7,0-9.5,0-14.2,0
		c-0.9,0-1.2-0.4-1.2-1.3c0.1-5.6,0.1-11.2,0.1-16.8c0-1.2-0.1-2.4-0.1-3.6c0-44.2,0-88.4,0-132.6c0-46.3,0-92.5,0-138.8
		c0-46.5,0-93,0-139.5c0-6.7,0-13.5,0-20.2c0-1.5,0.5-1.8,1.9-1.8c4.4,0.1,8.9,0.1,13.3,0c1.1,0,1.6,0.3,1.5,1.5c-0.1,0.5,0,1,0,1.5
		C497.5,100.5,497.5,175.3,497.5,250.1z"/>
	<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M250,497.5c-74.9,0-149.7,0-224.6,0c-0.5,0-1.2,0.1-1.6,0c-0.4-0.2-1-0.7-1-1c-0.1-4.8,0-9.6-0.1-14.4
		c0-0.8,0.3-1.2,1.2-1.2c5.6,0.1,11.2,0.1,16.8,0.1c1.2,0,2.5-0.1,3.7-0.1c44.1,0,88.1,0,132.2,0c46.3,0,92.5,0,138.8,0
		c46.5,0,92.9,0,139.4,0c6.9,0,13.8,0,20.6,0c1.4,0,1.9,0.3,1.9,1.8c-0.1,4.2,0,8.4,0,12.6c0,2.2,0,2.2-2.2,2.2
		c-16.5,0-32.9,0-49.4,0C367.1,497.5,308.6,497.5,250,497.5z"/>
	<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M2.5,250.1c0-75,0-150,0-225c0-2.6-0.2-2.3,2.3-2.3c4.2,0,8.5,0,12.7,0c1.1,0,1.6,0.3,1.6,1.5
		C19.1,29.8,19,35.4,19,41c0,1,0.1,2.1,0.1,3.1c0,44.1,0,88.1,0,132.2c0,46.4,0,92.8,0,139.2c0,46.7,0,93.3,0,140c0,6.7,0,13.4,0,20
		c0,1.3-0.3,1.8-1.7,1.7c-4.5-0.1-9-0.1-13.5,0c-1.1,0-1.6-0.3-1.5-1.4c0.1-0.5,0-1,0-1.5C2.5,399.6,2.5,324.8,2.5,250.1z"/>
	<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M424,43c10.4,0,20.8,0,31.1,0c1.5,0,1.9,0.4,1.9,1.9c0,20.8,0,41.5,0,62.3c0,1.5-0.4,2-1.9,1.9
		c-10.4-0.2-20.7-0.2-31.1-0.3c-0.6,0-1.3,0-1.8-0.2c-4.6-2.3-9.3-4.4-13.7-6.9c-6.7-3.9-10.9-9.9-14.1-16.8c-1-2.2-2.1-4.3-3-6.5
		c-0.4-1-0.7-2.1-0.7-3.1c0-9.2,0.1-18.4,0.2-27.5c0-0.9,0.1-1.8,0.1-2.7c0-1.9,0-1.9,2-1.9C403.4,43,413.7,43,424,43z"/>
	<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M76,43c10.4,0,20.8,0,31.1,0c1.4,0,1.9,0.3,1.9,1.8c-0.1,10.2-0.2,20.4-0.3,30.5c0,0.6,0,1.3-0.2,1.8
		c-2.7,5.5-4.8,11.2-8.5,16.1c-3.5,4.8-8.1,8.2-13.3,10.9c-2.9,1.5-5.8,2.8-8.8,4.1c-1,0.4-2.1,0.7-3.2,0.7c-10,0.1-20.1,0-30.1,0.1
		c-1.4,0-1.8-0.4-1.8-1.8c0-20.8,0-41.6,0-62.4c0-1.4,0.4-1.8,1.8-1.8C55.1,43,65.6,43,76,43z"/>
	<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M423.9,457c-10.3,0-20.7,0-31,0c-1.4,0-1.9-0.3-1.9-1.8c-0.1-9.9-0.3-19.8-0.2-29.8c0-1.7,0.9-3.4,1.7-5
		c2.2-4.3,4.2-8.6,6.8-12.6c4-6,10.1-9.4,16.4-12.6c4.9-2.5,9.7-3.5,15.2-3.3c8.1,0.3,16.3,0,24.4-0.1c1.4,0,1.9,0.4,1.9,1.9
		c0,20.5,0,41,0,61.5c0,1.5-0.5,1.8-1.9,1.8C444.7,457,434.3,457,423.9,457z"/>
	<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M75.9,457c-10.3,0-20.7,0-31,0c-1.5,0-2-0.3-2-1.9c0.1-20.5,0.1-40.9,0-61.4c0-1.5,0.3-2,1.9-2
		c9.9,0.1,19.8,0,29.6,0.1c1.2,0,2.5,0.3,3.6,0.8c3.9,1.8,7.9,3.7,11.6,5.8c7.2,4.1,12,10.4,15.3,17.8c0.7,1.5,1.3,3.1,2.2,4.6
		c1.2,2.2,1.5,4.4,1.5,6.9c0,9,0.1,18,0.2,26.9c0,2.3,0,2.3-2.4,2.3C96.4,457,86.2,457,75.9,457z"/>
	<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M477.3,250.1c0,21.9,0,43.8,0,65.7c0,2.1,0,2.1-2,2.1c-4.1,0-8.3,0-12.4,0c-2.2,0-2.2,0-2.2-2.1
		c0-21,0-42,0-63c0-22.8,0-45.7,0-68.5c0-2,0-2,2-2c4.3,0,8.6,0.1,12.9,0c1.4,0,1.8,0.4,1.8,1.8C477.3,206,477.3,228.1,477.3,250.1z
		"/>
	<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M39.4,389.1c0,1.1,0,2.3,0,3.4c0,20.8,0,41.6-0.1,62.4c0,2,0,2-2.1,2c-4.2,0-8.5-0.1-12.7,0
		c-1.4,0-1.9-0.4-1.9-1.9c0-21.7,0-43.4,0-65.1c0-22.2,0-44.4,0-66.6c0-2.1,0-2.1,2.1-2.1c4.3,0,8.7,0,13,0c1.1,0,1.6,0.2,1.6,1.5
		C39.4,344.9,39.4,367,39.4,389.1z"/>
	<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M22.7,249.9c0-21.9,0-43.8,0-65.7c0-2,0-2,1.9-2c4.4,0,8.8-0.1,13.2-0.2c1.2,0,1.7,0.4,1.5,1.5
		c0,0.1,0,0.3,0,0.4c0,21.1,0.1,42.2,0.1,63.3c0,22.8,0,45.6,0,68.4c0,2,0,2-2,2c-4.3,0-8.6-0.1-12.9,0c-1.4,0-1.9-0.4-1.9-1.8
		C22.7,293.9,22.7,271.9,22.7,249.9z"/>
	<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M389.2,477.2c-21.9,0-43.8,0-65.7,0c-2.1,0-2.1,0-2.1-2.1c0-4.1,0-8.3,0-12.4c0-2.1,0-2.1,2-2.1
		c21,0,41.9,0,62.9,0c22.8,0,45.6,0,68.4,0c2.4,0,2.4,0,2.4,2.4c0,4,0,8.1,0,12.1c0,2.1,0,2.2-2.1,2.2
		C433,477.2,411.1,477.2,389.2,477.2z"/>
	<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M477.3,110.9c0,21.9,0,43.7,0,65.6c0,2.2,0,2.2-2.2,2.2c-4.1,0-8.3,0-12.4,0c-2,0-2,0-2-2c0-21,0-42,0-63
		c0-22.8,0-45.6,0-68.4c0-2.3,0-2.3,2.3-2.3c4.1,0,8.3,0.1,12.4,0c1.6,0,1.9,0.5,1.9,2C477.3,66.9,477.3,88.9,477.3,110.9z"/>
	<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M22.7,110.7c0-21.9,0-43.8,0-65.7c0-2.1,0-2.1,2-2.1c4.2,0,8.5,0.1,12.7,0c1.5,0,2,0.4,1.9,1.9
		c-0.1,21.1,0,42.1,0,63.2c0,22.8,0,45.6,0,68.4c0,2.1,0,2.1-2.1,2.1c-4.1,0-8.3,0.1-12.4,0.1c-2.1,0-2.1,0-2.1-2
		C22.7,154.7,22.7,132.7,22.7,110.7z"/>
	<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M76.9,460.6c10,0,20,0,29.9,0c23.2,0,46.4,0,69.6,0c2.2,0,2.2,0,2.2,2.1c0,4,0,8,0,12c0,2.5,0,2.5-2.5,2.5
		c-21.1,0-42.1,0-63.2,0c-22.7,0-45.3,0-68,0c-2,0-2,0-2.1-2c0-4.1,0-8.2,0-12.3c0-2.3,0-2.3,2.2-2.3
		C55.8,460.6,66.4,460.6,76.9,460.6z"/>
	<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M249.9,477.2c-21.7,0-43.4,0-65.1,0c-2.8,0-2.5,0.3-2.5-2.6c0-4,0.1-8.1,0-12.1c0-1.5,0.4-1.9,1.9-1.9
		c21.1,0,42.1,0,63.2,0c22.7,0,45.5,0,68.2,0c2.3,0,2.3,0,2.3,2.3c0,4.1-0.1,8.3,0,12.4c0,1.5-0.4,1.9-1.9,1.9
		C293.9,477.2,271.9,477.2,249.9,477.2z"/>
	<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M477.3,389.3c0,21.9,0,43.7,0,65.6c0,2.2,0,2.2-2.2,2.2c-4.1,0-8.3,0-12.4,0c-2,0-2,0-2-2
		c0-20.8,0-41.5,0-62.3c0-23,0-46,0-69c0-2.3,0-2.3,2.3-2.3c4.2,0,8.4,0.1,12.6,0c1.4,0,1.7,0.5,1.7,1.8
		C477.3,345.3,477.3,367.3,477.3,389.3z"/>
	<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M110.9,22.8c21.9,0,43.7,0,65.6,0c2.1,0,2.1,0,2.1,2.1c0,4.2-0.1,8.5,0,12.7c0,1.5-0.6,1.8-1.9,1.8
		c-21.3,0-42.5,0-63.8,0c-22.6,0-45.2,0-67.8,0c-2.2,0-2.2,0-2.2-2.2c0-4.2,0.1-8.4,0-12.6c0-1.4,0.4-1.8,1.8-1.8
		C66.8,22.8,88.9,22.8,110.9,22.8z"/>
	<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M250.2,22.8c21.9,0,43.8,0,65.7,0c2,0,2,0,2,1.9c0,4.1,0,8.3,0,12.4c0,2.2,0,2.2-2.2,2.2c-21,0-41.9,0-62.9,0
		c-22.8,0-45.6,0-68.4,0c-2.2,0-2.2,0-2.2-2.2c0-4.1,0-8.2,0-12.3c0-2.1,0-2.1,2.1-2.1C206.2,22.8,228.2,22.8,250.2,22.8z"/>
	<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M389.1,22.8c22,0,43.9,0,65.9,0c2,0,2,0,2,2c0,4.2,0,8.4,0,12.6c0,2,0,2-2,2c-21.1,0-42.2,0-63.3,0
		c-22.6,0-45.3,0-67.9,0c-2.3,0-2.3,0-2.3-2.4c0-4.1,0-8.2,0-12.3c0-2,0-2,1.9-2C345.3,22.8,367.2,22.8,389.1,22.8z"/>
	<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M469,477.2c-2.3,0-4.6,0-6.9,0c-1,0-1.5-0.3-1.4-1.3c0-4.7,0-9.4,0-14.1c0-0.9,0.3-1.3,1.2-1.3
		c4.7,0,9.5,0,14.2,0c0.9,0,1.2,0.4,1.2,1.3c0,4.7,0,9.4,0,14.1c0,1.1-0.5,1.4-1.5,1.3C473.5,477.2,471.2,477.2,469,477.2z"/>
	<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M31,477.2c-2.3,0-4.6,0-6.9,0c-1,0-1.4-0.2-1.4-1.3c0-4.7,0-9.4,0-14.1c0-0.9,0.3-1.3,1.2-1.3
		c4.7,0,9.5,0,14.2,0c0.9,0,1.2,0.4,1.2,1.3c0,4.7,0,9.4,0,14.1c0,1.1-0.5,1.3-1.5,1.3C35.6,477.2,33.3,477.2,31,477.2z"/>
	<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M19.1,10.8c0,2.3,0,4.6,0,6.9c0,1-0.2,1.5-1.4,1.5c-4.6-0.1-9.3,0-13.9,0c-1,0-1.3-0.3-1.3-1.3
		c0-4.7,0-9.5,0-14.2c0-0.9,0.4-1.2,1.3-1.2c4.7,0,9.4,0,14,0c1.1,0,1.4,0.4,1.3,1.4C19.1,6.2,19.1,8.5,19.1,10.8z"/>
	<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M22.7,31.1c0-2.3,0-4.6,0-6.9c0-1,0.2-1.4,1.3-1.4c4.7,0.1,9.4,0,14.1,0c1,0,1.3,0.4,1.3,1.3
		c0,4.6,0,9.3,0,13.9c0,1.1-0.4,1.4-1.4,1.4c-4.6,0-9.3,0-13.9,0c-1.1,0-1.4-0.5-1.3-1.4C22.8,35.6,22.7,33.4,22.7,31.1z"/>
	<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M489.2,19.2c-2.3,0-4.6-0.1-6.9,0c-1.1,0-1.5-0.4-1.5-1.5c0-4.6,0-9.2,0-13.8c0-1,0.2-1.5,1.3-1.4
		c4.7,0,9.4,0,14.1,0c0.9,0,1.2,0.3,1.2,1.2c0,4.7,0,9.5,0,14.2c0,0.9-0.3,1.2-1.2,1.2C493.9,19.1,491.5,19.2,489.2,19.2z"/>
	<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M477.3,31.1c0,2.3,0,4.6,0,6.9c0,1-0.3,1.4-1.3,1.4c-4.7,0-9.4,0-14.1,0c-0.9,0-1.2-0.3-1.2-1.2
		c0-4.7,0-9.5,0-14.2c0-0.9,0.4-1.2,1.3-1.2c4.7,0,9.4,0,14.1,0c1.1,0,1.3,0.5,1.3,1.4C477.2,26.5,477.3,28.8,477.3,31.1z"/>
	<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M497.5,489c0,2.3,0,4.7,0,7c0,1-0.3,1.4-1.4,1.4c-4.6,0-9.3,0-13.9,0c-0.9,0-1.4-0.3-1.3-1.3
		c0-4.7,0-9.4,0-14.1c0-1,0.4-1.3,1.4-1.3c4.6,0,9.3,0,13.9,0c1.1,0,1.4,0.5,1.3,1.5C497.4,484.5,497.5,486.8,497.5,489z"/>
	<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M10.8,497.5c-2.3,0-4.6,0-6.9,0c-1.1,0-1.5-0.4-1.5-1.5c0-4.6,0-9.2,0-13.7c0-1,0.3-1.4,1.4-1.4
		c4.6,0,9.3,0,13.9,0c1,0,1.4,0.4,1.4,1.4c0,4.6,0,9.2,0,13.7c0,1.1-0.3,1.5-1.4,1.5C15.4,497.4,13.1,497.5,10.8,497.5z"/>
	<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M388.9,419.6c-2.9-6.2-5.5-12.1-9.8-16.7c3.4-3.3,6.6-6.5,9.7-9.7c3.1,3.1,6.4,6.3,9.7,9.7
		C394.3,407.6,391.7,413.4,388.9,419.6z"/>
	<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M388.9,81.3c2.7,6,5.4,11.9,9.7,16.6c-3.4,3.4-6.6,6.6-10.1,10.1c-1.1-1.2-2.3-2.5-3.5-3.8
		c-1.8-1.8-3.6-3.6-5.4-5.4c-0.3-0.3-0.4-1.1-0.2-1.3c3.9-4.5,6.4-9.9,8.9-15.3C388.5,82,388.6,81.8,388.9,81.3z"/>
	<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M401.3,100.6c5,4.5,10.8,7.1,16.9,10c-6.2,2.8-12,5.5-17.1,10.1c-1.1-1.2-2.1-2.5-3.2-3.6
		c-1.9-1.9-3.8-3.8-5.7-5.7c-0.3-0.3-0.5-1.1-0.3-1.3C395,106.9,398.1,103.8,401.3,100.6z"/>
	<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M401.2,400.3c-2.4-2.5-4.8-4.9-7.1-7.3c-2.7-2.8-2.8-2.8,0-5.5c2.4-2.4,4.7-4.7,7.2-7.2
		c4.9,4.4,10.7,7.1,16.9,9.9C412.1,393.1,406.2,395.6,401.2,400.3z"/>
	<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M359.8,390c5.8-2.7,11.6-5.3,16.4-9.6c3.3,3.3,6.5,6.6,9.8,9.9c-0.1,0.1-0.4,0.5-0.7,0.8c-2.7,2.7-5.3,5.4-8,8
		c-0.5,0.5-0.9,1-1.6,0.4c-4.4-3.8-9.6-6.2-14.7-8.7c-0.3-0.1-0.5-0.3-0.8-0.4C360.1,390.4,360,390.2,359.8,390z"/>
	<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M139.6,110.5c-4.1,2.2-8.1,4.4-12,6.6c-1.3,0.7-2.5,1.7-3.6,2.7c-0.7,0.6-1.1,0.6-1.7,0c-3-3-6-6-9.1-9
		c0.3-0.4,0.5-0.7,0.8-1c2.7-2.8,5.5-5.5,8.2-8.3c0.5-0.5,0.9-0.8,1.6-0.2c4.3,3.8,9.4,6.2,14.5,8.5
		C138.7,110,139.1,110.3,139.6,110.5z"/>
	<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M100.8,123.3c3.4-3.3,6.6-6.6,9.8-9.8c3.1,3.1,6.4,6.3,9.6,9.5c-1.7,2.6-3.6,5.2-5.2,7.9
		c-1.6,2.8-2.9,5.7-4.5,8.9C107.7,133.8,105.1,128,100.8,123.3z"/>
	<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M139.8,390.2c-2,1-4,1.8-5.7,2.8c-3.4,2-6.7,4.1-10,6.3c-0.9,0.6-1.3,0.6-2.1-0.1c-2.6-2.7-5.3-5.3-7.9-7.9
		c-0.3-0.3-0.6-0.7-0.8-0.9c3.3-3.3,6.5-6.5,9.7-9.7c2.7,1.8,5.3,3.7,8.1,5.3C133.8,387.4,136.7,388.6,139.8,390.2z"/>
	<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M260.2,263.3c-4.5,4.9-7,10.7-9.9,16.8c-3-6-5.5-11.9-10.2-17c1.3-1.2,2.7-2.4,3.9-3.6
		c1.8-1.8,3.6-3.6,5.4-5.4c0.3-0.3,1.2-0.4,1.4-0.2C253.9,257.1,257,260.1,260.2,263.3z"/>
	<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M110.4,387.1c-3.1-3.1-6.3-6.3-9.6-9.7c4.3-4.6,6.9-10.5,9.8-16.6c2.9,6.1,5.4,11.9,9.7,16.6
		C117,380.7,113.7,383.9,110.4,387.1z"/>
	<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M279.5,250.4c-2.1,1-3.8,1.8-5.4,2.8c-3.5,2.2-7,4.5-10.5,6.7c-0.4,0.2-1.2,0.4-1.4,0.2c-3.1-3-6.2-6-9.4-9.2
		c2.8-2.8,5.4-5.4,8.1-8c1.9-1.9,1.9-1.9,4.1-0.3c3.7,2.9,7.9,4.8,12.1,6.7C277.6,249.6,278.3,249.9,279.5,250.4z"/>
	<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M388.9,360.9c0.9,2,1.7,3.8,2.7,5.4c2.1,3.5,4.3,6.9,6.4,10.4c0.2,0.4,0.5,1.2,0.3,1.5c-3,3.2-6.1,6.3-9.4,9.6
		c-2.6-2.7-5.1-5.3-7.6-7.8c-2.2-2.2-2.2-2.2-0.3-4.6c3.1-3.9,5.1-8.4,7.2-12.9C388.3,362.1,388.5,361.6,388.9,360.9z"/>
	<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M386.2,110.4c-3.4,3.4-6.6,6.6-10,10c-4.7-4.2-10.5-7-16.5-9.6c0.4-0.3,0.6-0.6,0.8-0.7
		c5.4-2.4,10.7-5,15.2-8.8c0.2-0.2,0.9-0.2,1.1,0C380,104.2,383.1,107.3,386.2,110.4z"/>
	<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M388.8,113c3.2,3.2,6.3,6.3,9.3,9.4c0.2,0.2,0.3,0.9,0.1,1.1c-4,4.6-6.5,10.1-9.1,15.6
		c-0.1,0.1-0.2,0.2-0.4,0.5c-1-2.1-1.8-4.1-2.9-6c-1.9-3.3-4.1-6.5-6.1-9.7c-0.2-0.4-0.4-1.2-0.2-1.4
		C382.5,119.3,385.6,116.3,388.8,113z"/>
	<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M247.7,250.8c-3.2,3.2-6.3,6.3-9.5,9.4c-0.2,0.2-0.9,0.2-1.1,0c-4.5-4-9.9-6.6-15.3-9.2
		c-0.2-0.1-0.3-0.2-0.6-0.5c0.4-0.3,0.8-0.5,1.1-0.7c5.2-2.2,10.3-4.6,14.8-8.3c0.2-0.2,1-0.1,1.2,0.1
		C241.4,244.6,244.5,247.6,247.7,250.8z"/>
	<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M100.8,402.8c3.3-3.3,6.5-6.6,9.6-9.7c3.2,3.2,6.5,6.5,9.8,9.9c-4.1,4.5-6.7,10.3-9.6,16.5
		C107.7,413.4,105.1,407.5,100.8,402.8z"/>
	<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M100.9,97.8c4.2-4.6,6.8-10.4,9.7-16.6c2.9,6.2,5.5,12,9.7,16.7c-3.3,3.3-6.6,6.6-9.7,9.7
		C107.4,104.5,104.2,101.2,100.9,97.8z"/>
	<path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M260.2,238.4c-3.2,3.2-6.3,6.3-9.4,9.3c-0.2,0.2-0.9,0.2-1.1,0c-3.2-3-6.3-6.1-9.4-9.3
		c4.6-4.9,7.1-10.7,10-16.9C253.1,227.7,255.6,233.5,260.2,238.4z"/>
	<path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M97.9,120.3c-4.7-4.2-10.5-6.8-16.4-9.6c0.4-0.3,0.6-0.6,0.9-0.7c5.3-2.4,10.7-5,15.2-8.9
		c0.2-0.2,0.8-0.2,1-0.1c3.2,3.1,6.3,6.2,9.4,9.3C104.5,113.8,101.2,117,97.9,120.3z"/>
	<path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M81.3,390.1c6.1-2.8,11.9-5.4,16.5-9.6c3.3,3.3,6.6,6.6,10.2,10.2c-0.8,0.7-1.9,1.5-2.8,2.4
		c-2.1,2-4.1,4.1-6.1,6.1c-0.5,0.5-0.9,0.9-1.6,0.2c-4.1-3.6-9-6-13.9-8.3C82.8,390.9,82.2,390.6,81.3,390.1z"/>





</g>
            </svg>
        </div>
    )
}