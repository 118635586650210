import React from 'react';


export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>
  
  
<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M170,252.2c17.9,0,35.7,0,53.6-0.1c2.5,0,4.2,0.7,6,2.5c5.6,5.7,11.2,11.4,16.9,17c1.3,1.2,1.9,2.5,1.9,4.3
	c0,36.3,0,72.7,0,109c0,2-0.6,2.7-2.6,2.7c-30.6-0.1-61.1,0-91.7-0.1c-1.4,0-3.1-0.7-4.1-1.7c-12-11.9-24-23.9-36-35.8
	c-1.3-1.3-1.8-2.7-1.8-4.6c0.1-30.2,0.1-60.3,0.1-90.5c0-2.4,0.8-2.9,3-2.8C133.5,252.3,151.8,252.2,170,252.2z"/>
<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M248.3,170.1c0,18.2,0,36.3,0,54.5c0,2-0.6,3.4-2,4.7c-6,5.8-11.8,11.9-17.9,17.7c-0.9,0.9-2.4,1.6-3.7,1.6
	c-36.7,0.1-73.3,0.1-110,0.1c-1.9,0-2.6-0.6-2.6-2.5c0.1-29.4,0-58.7,0.1-88.1c0-1.2,0.7-2.8,1.6-3.7c13.4-13.5,26.8-27,40.3-40.3
	c1-1,2.7-1.7,4.1-1.7c29-0.1,58,0,87-0.1c2.2,0,2.9,0.6,2.9,2.9c-0.1,18.3,0,36.7,0,55C248.3,170.1,248.3,170.1,248.3,170.1z"/>
<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M251.9,169.8c0-18.4,0-36.8,0-55.2c0-1.6,0.5-2.4,2.1-2.2c0.4,0,0.8,0,1.2,0c28.6,0,57.2,0,85.8,0
	c1.9,0,3.4,0.5,4.8,1.9c13.2,13.3,26.6,26.6,39.8,39.9c0.9,0.9,1.6,2.6,1.6,3.9c0.1,29.3,0.1,58.6,0.1,87.9c0,2-0.6,2.5-2.5,2.5
	c-9.2-0.1-18.5,0-27.7,0c-27,0.1-53.9,0.1-80.9,0.3c-1.9,0-3.2-0.5-4.6-1.9c-5.8-5.9-11.7-11.8-17.6-17.6c-1.5-1.4-2-3-2-5
	c0.1-17.3,0-34.6,0-51.9c0-0.9,0-1.7,0-2.6C252,169.8,251.9,169.8,251.9,169.8z"/>
<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M252,330.3c0-17.9,0-35.9,0-53.8c0-2,0.6-3.5,2.1-5c5.9-5.7,11.7-11.6,17.5-17.4c1.3-1.3,2.7-1.8,4.6-1.8
	c36,0,71.9-0.1,107.9-0.2c3.3,0,3.4,0,3.4,3.3c0,28.7,0,57.3,0,86c0,2.1-0.7,3.6-2.2,5.1c-13.2,13.1-26.3,26.2-39.4,39.4
	c-1.3,1.3-2.6,1.8-4.3,1.8c-28.8,0-57.7-0.1-86.5,0c-2.5,0-3-0.8-3-3.1C252,366.5,252,348.4,252,330.3z"/>
<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M180.2,457.1c-21.7,0-43.4,0-65.1,0c-2.3,0-2.9-0.6-2.9-2.9c0.1-7.1,0-14.3,0.1-21.4c0-1.3,0.7-2.9,1.6-3.9
	c12-12.1,24.1-24.2,36.2-36.2c0.9-0.9,2.4-1.5,3.7-1.5c30.7-0.1,61.4-0.1,92.2-0.1c1.9,0,2.4,0.6,2.4,2.5c-0.1,20.4-0.1,40.7,0,61.1
	c0,2-0.7,2.4-2.6,2.4C223.9,457.1,202.1,457.1,180.2,457.1z"/>
<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M42.9,387.2c0-1,0-1.9,0-2.7c0-43.1,0-86.1,0-129.2c0-3.1,0-3.1,3.1-3.1c19.9,0,39.8,0,59.7-0.1
	c2.4,0,3,0.7,3,3.1c-0.1,30.2-0.1,60.5-0.1,90.7c0,1.7-0.5,2.9-1.7,4.1c-11.9,11.9-23.9,23.8-35.7,35.7c-1.3,1.3-2.7,1.9-4.5,1.9
	c-7.1-0.1-14.3-0.2-21.4-0.2C44.5,387.4,43.8,387.3,42.9,387.2z"/>
<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M457,248.3c-1.1,0-2,0-2.8,0c-20.1,0-40.3,0-60.4,0.1c-2.4,0-3-0.7-3-3.1c0.1-28.9,0.1-57.8,0-86.7
	c0-2,0.5-3.4,1.9-4.8c13.3-13.2,26.5-26.4,39.7-39.7c1.3-1.3,2.6-2,4.5-1.9c5.7,0.2,11.4,0.3,17.1,0.2c2.2,0,2.9,0.6,2.9,2.9
	c-0.1,33,0,65.9,0,98.9c0,10.4,0,20.8,0,31.2C457,246.4,457,247.2,457,248.3z"/>
<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M456.9,251.8c0,45.3,0,90.2,0,135.3c-0.6,0.1-1.1,0.3-1.6,0.3c-6.3,0.1-12.5,0.2-18.8,0.1
	c-1.2,0-2.8-0.7-3.7-1.5c-13.4-13.3-26.8-26.7-40.1-40.2c-1-1-1.6-2.7-1.6-4.1c-0.1-9.8-0.1-19.6-0.1-29.4c0-19-0.1-38.1-0.2-57.1
	c0-3.2,0-3.2,3.3-3.2c19.9-0.1,39.8-0.1,59.7-0.2C454.7,251.8,455.6,251.8,456.9,251.8z"/>
<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M180.7,43c21.7,0,43.4,0,65.1,0c2,0,2.4,0.7,2.4,2.6c0,20.2,0,40.4,0.1,60.6c0,2.2-0.7,2.7-2.8,2.7
	c-29.1-0.1-58.1-0.1-87.2,0c-1.9,0-3.2-0.6-4.5-1.9c-13-13.1-26.1-26.2-39.2-39.2c-1.5-1.5-2.1-3.1-2.1-5.2
	c0.1-5.7,0.2-11.4,0.1-17.1c0-2,0.6-2.5,2.5-2.5C136.9,43,158.8,43,180.7,43z"/>
<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M42.9,180.6c0-21.7,0-43.4,0-65.1c0-2.2,0.6-3,2.9-2.9c5.8,0.2,11.6,0,17.4,0.1c1.3,0,2.9,0.7,3.9,1.6
	c13.4,13.3,26.7,26.6,40,40c0.9,0.9,1.5,2.4,1.6,3.6c0.1,29.5,0.1,58.9,0.1,88.4c0,1.8-0.6,2.4-2.4,2.4c-20.3-0.1-40.6-0.1-60.9,0
	c-2,0-2.5-0.7-2.5-2.6C42.9,224.3,42.9,202.4,42.9,180.6z"/>
<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M319.6,457.1c-21.5,0-43.1,0-64.6,0c-2.2,0-3-0.4-3-2.8c0.1-20.1,0.1-40.3,0-60.4c0-2.1,0.5-2.9,2.8-2.8
	c28.9,0.1,57.8,0.1,86.7,0c1.8,0,3.1,0.6,4.3,1.8c13.2,13.3,26.6,26.6,39.8,39.9c1,1,1.6,2.7,1.7,4.1c0.2,5.7,0,11.4,0.1,17.1
	c0.1,2.3-0.5,3-3,3C362.8,457.1,341.2,457.1,319.6,457.1z"/>
<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M319.7,43c21.6,0,43.2,0,64.9,0c2.2,0,3,0.6,2.9,2.9c-0.2,5.9-0.1,11.7-0.2,17.6c0,1.1-0.6,2.5-1.4,3.3
	c-13.5,13.6-27.1,27.2-40.7,40.7c-0.8,0.8-2.3,1.4-3.5,1.4c-29.1,0.1-58.3,0-87.4,0.1c-2,0-2.5-0.7-2.5-2.6
	c0.1-20.3,0.1-40.6,0-60.9c0-2,0.7-2.5,2.6-2.5C276.1,43,297.9,43,319.7,43z"/>
<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M22.6,18.9c0-5.1,0-10.1,0.1-15.1c0-0.4,0.8-1,1.3-1.2c0.6-0.2,1.4-0.1,2.1-0.1c149.2,0,298.4,0,447.7,0
	c3.5,0,3.5,0,3.5,3.6c0,4.2,0,8.4,0,12.8C325.7,18.9,174.3,18.9,22.6,18.9z"/>
<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M2.5,249.8c0-74.5,0-149,0-223.5c0-4-0.2-3.5,3.5-3.5c3.3,0,6.6,0,9.9,0c3.1,0,3.1,0,3.1,3.3c0,7,0,13.9,0,20.9
	c0,42.5,0,85.1,0,127.6c0,46.3,0,92.6,0,138.9c0,46.8,0,93.7,0,140.5c0,6.9-0.1,13.8,0,20.7c0,1.9-0.5,2.6-2.5,2.6
	c-3.9-0.1-7.8-0.1-11.8,0c-1.9,0.1-2.5-0.7-2.3-2.5c0.1-1.9,0-3.8,0-5.6C2.5,396,2.5,322.9,2.5,249.8z"/>
<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M480.9,477.2c0-1.4,0-2.4,0-3.4c0-48.7,0-97.4,0-146.2c0-93.8,0-187.5,0-281.3c0-7.1,0-14.3,0-21.4
	c0-1.7,0.5-2.3,2.3-2.3c4.1,0.1,8.1,0.1,12.2,0c1.7,0,2.3,0.6,2.2,2.2c-0.1,0.7,0,1.4,0,2.1c0,148.7,0,297.3,0,446
	c0,4.6,0.4,4.1-4.2,4.1C489.2,477.2,485.2,477.2,480.9,477.2z"/>
<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M477.3,497.3c-1.2,0.1-2.1,0.1-3,0.1c-149.6,0-299.2,0-448.8,0.1c-2.4,0-3.1-0.7-3-3.1c0.2-3.6,0.2-7.2,0-10.8
	c-0.1-2,0.5-2.7,2.6-2.7c7.1,0.1,14.3,0,21.4,0c42.7,0,85.4,0,128.1,0c46.4,0,92.8,0,139.2,0c46.7,0,93.4,0,140.1,0
	c7,0,13.9,0.1,20.9,0c2,0,2.5,0.6,2.5,2.6C477.2,488,477.3,492.5,477.3,497.3z"/>
<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M108.5,457c-1.1,0.1-1.9,0.1-2.6,0.1c-20.1,0-40.3,0-60.4,0c-2,0-2.7-0.5-2.7-2.6c0.1-20.3,0.1-40.5,0-60.8
	c0-1.9,0.5-2.6,2.5-2.6c7.2,0.1,14.4,0,21.6,0.1c1.3,0,2.8,0.6,3.7,1.5c12.2,12.1,24.4,24.3,36.6,36.5c0.6,0.6,1.2,1.4,1.2,2.1
	C108.6,439.8,108.5,448.3,108.5,457z"/>
<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M456.8,391.1c0,22,0,43.8,0,66c-1,0-1.8,0-2.6,0c-20.1,0-40.3,0-60.4,0c-2.2,0-3-0.6-2.9-2.9
	c0.2-5.8,0-11.6,0.1-17.4c0-1.3,0.7-2.9,1.6-3.9c13.3-13.5,26.7-26.8,40.2-40.2c0.8-0.8,2.1-1.6,3.2-1.6c6.6-0.2,13.1-0.2,19.7-0.3
	C456,390.9,456.3,391,456.8,391.1z"/>
<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M456.8,43.2c0,0.9,0.1,1.6,0.1,2.4c0,20.2,0,40.4,0,60.6c0,3,0,2.8-3.1,2.7c-5.5-0.2-11-0.2-16.5-0.1
	c-2.1,0-3.6-0.6-5.2-2.1c-13.1-13.2-26.3-26.4-39.5-39.5c-1.4-1.4-2-2.8-1.9-4.8c0.2-5.8,0.2-11.6,0.3-17.4c0-1.4,0.4-2.1,2-2.1
	c20.9,0,41.8,0,62.8,0.1C456,43,456.3,43.1,456.8,43.2z"/>
<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M42.9,76c0-10.2,0-20.4,0-30.5c0-1.9,0.5-2.5,2.5-2.5c20.4,0.1,40.7,0.1,61.1,0c2,0,2.5,0.7,2.5,2.6
	c-0.1,5.9,0,11.9-0.2,17.8c0,1.2-0.6,2.7-1.4,3.5c-13.4,13.6-27,27.1-40.5,40.5c-0.7,0.7-2,1.3-3,1.3c-6,0.2-12.1,0.2-18.1,0.3
	c-2.1,0.1-2.9-0.5-2.8-2.8C43,96.2,42.9,86.1,42.9,76z"/>
<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M39.3,182.2c0,1.3,0,2.2,0,3.1c0,43.1,0,86.2,0,129.2c0,3.3,0,3.3-3.3,3.3c-3.7,0-7.4-0.1-11,0
	c-1.9,0.1-2.5-0.7-2.4-2.5c0.1-2.2,0-4.4,0-6.6c0-41.1,0-82.2,0-123.4c0-3.3,0-3.3,3.2-3.3C30.2,182.2,34.5,182.2,39.3,182.2z"/>
<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M39.2,43.4c0,45,0,89.9,0,135c-0.6,0.1-1.2,0.3-1.8,0.3c-4.2,0-8.3-0.1-12.5,0c-1.9,0.1-2.6-0.6-2.4-2.5
	c0-0.3,0-0.6,0-0.9c0-43,0-86,0-128.9c0-3.3,0-3.3,3.3-3.3c4.2,0,8.5,0,12.7,0.1C38.7,43.1,38.9,43.2,39.2,43.4z"/>
<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M39.2,321.6c0,45.3,0,90.3,0,135.5c-5.2,0-10.3,0-15.4,0c-0.4,0-1-0.7-1.1-1.1c-0.2-0.6-0.1-1.2-0.1-1.9
	c0-43.1,0-86.2,0-129.2c0-3.3,0-3.3,3.4-3.3C30.4,321.6,34.7,321.6,39.2,321.6z"/>
<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M42.9,460.6c1.2,0,2.1,0,3,0c43.3,0,86.5,0,129.8,0c2.3,0,3,0.6,2.9,2.9c-0.2,3.8-0.1,7.7,0,11.5
	c0,1.8-0.6,2.5-2.3,2.3c-0.3,0-0.6,0-0.9,0c-43,0-86,0-129.1,0c-3.3,0-3.3,0-3.3-3.4C42.9,469.5,42.9,465.3,42.9,460.6z"/>
<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M249.8,477.2c-21.3,0-42.6,0-63.9,0c-3.6,0-3.6,0-3.6-3.7c0-3.6,0.1-7.2,0-10.8c0-1.5,0.5-2.2,2-2
	c0.5,0,0.9,0,1.4,0c42.9,0,85.9,0,128.8,0c3.4,0,3.4,0,3.4,3.5c0,3.1,0,6.3,0,9.4c0,3.7,0,3.7-3.6,3.7
	C292.7,477.2,271.2,477.2,249.8,477.2z"/>
<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M321.7,460.7c45,0,90,0,135.2,0c0,5.2,0,10.2-0.1,15.3c0,0.4-0.7,0.9-1.2,1.1c-0.7,0.2-1.4,0.1-2.1,0.1
	c-42.9,0-85.9,0-128.8,0c-3.3,0-3.3,0-3.3-3.3c0-4.2,0-8.3,0-12.5C321.5,461.3,321.6,461.2,321.7,460.7z"/>
<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M477.3,250.1c0,21.5,0,43.1,0,64.6c0,3.1,0,3.1-3.1,3.1c-3.7,0-7.4-0.1-11,0c-2.1,0.1-2.6-0.7-2.6-2.7
	c0.1-20.5,0-41,0-61.5c0-22.7,0-45.4,0-68.1c0-3.3,0-3.3,3.3-3.3c3.6,0,7.2,0.1,10.8,0c2.1-0.1,2.6,0.6,2.6,2.7
	c-0.1,13.7,0,27.4,0,41.1C477.3,234,477.3,242,477.3,250.1z"/>
<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M250,22.8c21.6,0,43.3,0,64.9-0.1c2.3,0,3.2,0.5,3.1,3c-0.2,3.7-0.1,7.5,0,11.3c0,1.8-0.4,2.5-2.3,2.5
	c-21.8-0.1-43.6-0.2-65.3-0.2c-14.1,0-28.2,0.1-42.3,0.2c-7.7,0-15.4-0.1-23,0c-2,0-2.8-0.5-2.7-2.6c0.1-3.8,0.2-7.7,0-11.5
	c-0.1-2.2,0.8-2.6,2.7-2.6C206.6,22.8,228.3,22.8,250,22.8z"/>
<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M389.2,39.4c-21.6,0-43.2,0-64.8,0.1c-2.4,0-3.1-0.7-3-3c0.2-3.5,0.1-7,0.1-10.6c0-3.1,0-3.1,3-3.1
	c27,0,54,0,81.1,0c16.3,0,32.6,0,48.9,0c2.1,0,2.7,0.6,2.6,2.6c-0.2,3.4,0,6.7,0,10.1c0,4.3,0.6,3.9-3.8,3.9
	C431.8,39.4,410.5,39.4,389.2,39.4z"/>
<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M111,22.8c21.6,0,43.1,0,64.7-0.1c2.3,0,3,0.7,2.9,2.9c-0.2,3.8-0.1,7.7,0,11.5c0,1.5-0.4,2.3-2.1,2.3
	c-21.8-0.1-43.6-0.2-65.4-0.2c-21.2,0-42.5,0.1-63.7,0.1c-5.1,0-4.4,0.6-4.4-4.5c0-3.2,0.1-6.4,0-9.6c-0.1-2,0.6-2.5,2.6-2.5
	C67.3,22.8,89.2,22.8,111,22.8z"/>
<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M477.3,457.1c-5.4,0-10.4,0-15.5,0c-0.4,0-1-0.7-1.1-1.1c-0.2-0.6-0.1-1.4-0.1-2.1c0-43,0-86,0-128.9
	c0-3.3,0-3.3,3.3-3.3c3.8,0,7.7,0,11.5,0c1.4,0,2,0.5,1.9,1.9c-0.1,0.5,0,0.9,0,1.4c0,43,0,86,0,129
	C477.3,454.8,477.3,455.8,477.3,457.1z"/>
<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M460.6,110.7c0-21.7,0-43.4,0-65.1c0-2.1,0.6-2.7,2.6-2.7c4,0.2,8,0.1,12,0c1.7,0,2.3,0.6,2.1,2.2
	c0,0.4,0,0.8,0,1.2c0,43,0,86,0,129c0,3.3,0,3.3-3.3,3.3c-3.8,0-7.5-0.1-11.3,0c-1.6,0-2.1-0.5-2.1-2.2c0-21.2,0-42.3,0-63.5
	c0-0.8,0.1-1.6,0.2-2.3C460.7,110.7,460.7,110.7,460.6,110.7z"/>
<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M249.7,270.4c-1.5-1.7-2.9-3.5-4.4-5.1c-4.4-4.5-8.8-8.9-13.3-13.3c-1.2-1.2-1.4-1.9-0.1-3.2
	c5.7-5.5,11.2-11.1,16.8-16.7c1.2-1.3,2-1.2,3.2,0c5.5,5.6,11.1,11.2,16.7,16.8c0.8,0.8,1.6,1.5,0.4,2.7c-6.1,6-12.1,12-18.1,18
	c-0.2,0.2-0.4,0.2-0.6,0.3C250.1,270.1,249.9,270.3,249.7,270.4z"/>
<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M389.4,348.1c6.4,6.3,12.8,12.7,19.1,19.1c-6.4,6.4-12.9,12.8-19.3,19.1c-6.3-6.3-12.7-12.7-19-19.1
	C376.4,360.9,382.9,354.5,389.4,348.1z"/>
<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M412.1,370.1c4.4,4.5,9.2,9.3,13.8,14.1c1.7,1.7,4.4,3.5,4.5,5.4c0.1,1.6-2.8,3.4-4.4,5.1
	c-4.7,4.8-9.5,9.5-14.1,14.2c-6.6-6.6-13-13.1-19.2-19.4C398.8,383.3,405.3,376.8,412.1,370.1z"/>
<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M113.7,110.5c6.3-6.3,12.7-12.8,19-19.1c6.4,6.4,12.8,12.8,19.1,19.1c-6.3,6.3-12.8,12.7-19.1,19.1
	C126.5,123.4,120.1,116.9,113.7,110.5z"/>
<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M369.5,132.1c1.8-1.6,3.8-3.3,5.6-5.2c4.2-4.1,8.3-8.3,12.5-12.4c0.8-0.8,1.4-1.5,2.5-0.4
	c6,6.1,12,12.1,18,18.1c0.1,0.1,0.1,0.3,0.1,0.2c-6.5,6.5-13,12.9-19.3,19.2C382.8,145.5,376.4,139.1,369.5,132.1z"/>
<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M110.8,107.8c-6.5-6.5-12.9-13-19.5-19.6c0.3-0.3,0.8-0.9,1.4-1.5c5.4-5.4,10.7-10.7,16-16.1
	c1.3-1.3,2.1-1.6,3.5-0.1c5.4,5.6,10.9,11,16.4,16.4c1.2,1.2,1.3,1.9,0,3.2c-5.6,5.5-11.1,11-16.6,16.6
	C111.6,107.1,111.2,107.5,110.8,107.8z"/>
<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M385.9,389.5c-6.3,6.3-12.7,12.7-19.7,19.7c-0.9-1.2-1.7-2.6-2.9-3.7c-4.8-4.9-9.7-9.8-14.6-14.6
	c-0.8-0.8-1.5-1.4-0.4-2.5c6.1-6.1,12.2-12.3,18.2-18.3C373.2,376.7,379.6,383.2,385.9,389.5z"/>
<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M370,411.9c6.6-6.6,13-13,19.2-19.2c6.3,6.3,12.8,12.8,19.5,19.6c-1.9,1.8-3.9,3.6-5.9,5.6
	c-4.1,4-8.1,8.1-12.1,12.1c-0.8,0.8-1.4,1.4-2.5,0.4c-6-6.1-12-12.1-18-18.2C370,412,370,411.9,370,411.9z"/>
<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M430.2,110.7c-6.1,6.1-12.5,12.5-18.8,18.8c-6.3-6.3-12.8-12.7-19.1-19c6.3-6.3,12.7-12.6,19-18.9
	C417.5,97.9,423.9,104.3,430.2,110.7z"/>
<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M110.6,114c6.3,6.3,12.8,12.7,19.6,19.4c-3.5,3.4-7,6.8-10.5,10.2c-2.6,2.6-5.2,5.2-7.8,7.8
	c-0.8,0.8-1.4,1.1-2.3,0.1c-6.1-6.1-12.3-12.3-18.3-18.2C97.8,126.7,104.3,120.2,110.6,114z"/>
<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M88.4,91.6c6.3,6.3,12.7,12.7,18.9,19c-6.4,6.3-12.8,12.7-19.1,18.9c-6.1-6.1-12.5-12.6-18.9-18.9
	C75.6,104.4,82,98,88.4,91.6z"/>
<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M366.7,129.4c-6.2-6.1-12.7-12.6-19-18.9c6.3-6.3,12.8-12.7,19.1-19c6.2,6.3,12.6,12.8,18.9,19.2
	C379.6,116.7,373.1,123.1,366.7,129.4z"/>
<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M389.2,107.6c-6.5-6.5-13-12.9-19.2-19.1c6.4-6.4,12.8-12.8,19.4-19.4c0.3,0.2,0.9,0.7,1.4,1.3
	c5.4,5.4,10.7,10.8,16.1,16.1c1.3,1.3,1.6,2.1,0.1,3.5c-5.6,5.4-11,10.9-16.5,16.4C390.1,106.9,389.6,107.3,389.2,107.6z"/>
<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M89.5,407.4c-1-1.4-1.9-2.9-3.1-4.1c-4.1-4.2-8.3-8.3-12.4-12.5c-0.8-0.8-1.4-1.5-0.3-2.5
	c5.3-5.3,10.6-10.6,16-15.9c0.2-0.2,0.4-0.2,1.2-0.9c1.6,1.9,3.2,3.8,4.9,5.6c3.5,3.6,7.2,7.2,10.8,10.8c0.8,0.8,1.4,1.4,0.3,2.5
	c-5.4,5.3-10.8,10.7-16.1,16c-0.1,0.1-0.3,0.1-0.4,0.2C90,406.9,89.8,407.1,89.5,407.4z"/>
<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M127.8,410.1c-2.5,2.4-5.1,5-7.7,7.5c-2.8,2.8-5.5,5.6-8.3,8.3c-0.5,0.4-1.9,0.6-2.2,0.3
	c-5.5-5.4-10.9-10.8-16.3-16.3c-0.1-0.1-0.1-0.3-0.1-0.2c5.8-5.8,11.6-11.5,17.2-17.1C115.9,398.2,121.7,404,127.8,410.1z"/>
<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M110.6,386.7c-5.9-6-11.6-11.7-17.5-17.6c0.3-0.3,0.7-0.9,1.1-1.4c4.9-4.9,9.8-9.7,14.6-14.6
	c1.2-1.2,2-1.3,3.2-0.1c4.8,4.9,9.7,9.8,14.6,14.6c0.7,0.7,1.6,1.3,0.5,2.5C121.6,375.6,116.1,381.1,110.6,386.7z"/>
<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M131,406.7c-5.9-5.9-11.6-11.6-17.1-17.2c5.6-5.5,11.4-11.3,17.4-17.2c5.1,5.1,10.6,10.6,16.1,16.2
	c0.3,0.3,0.3,1.4,0,1.7C141.9,395.8,136.4,401.3,131,406.7z"/>
<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M39.2,460.6c0,5.6,0,10.8,0,16.6c-1.3,0-2.6,0-3.9,0c-3.5,0-7-0.1-10.6,0c-1.6,0-2.2-0.6-2.2-2.2
	c0.1-4.1,0-8.3,0.1-12.4c0-0.7,0.8-1.8,1.3-1.9C29,460.6,34,460.6,39.2,460.6z"/>
<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M39.3,31c0,2.2,0,4.4,0,6.6c0,1.3-0.4,1.8-1.7,1.8c-4.4,0-8.8,0-13.2,0c-1.3,0-1.8-0.5-1.8-1.8
	c0-4.3,0-8.6,0-12.9c0-1.4,0.6-1.9,2-1.9c4.3,0.1,8.6,0.1,12.9,0c1.5,0,1.9,0.6,1.8,1.9C39.3,26.8,39.3,28.9,39.3,31z"/>
<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M477.3,460.6c0,5.3,0,10.3-0.1,15.3c0,0.4-1,1.2-1.5,1.2c-4.5,0.1-8.9,0-13.4,0.1c-1.4,0-1.7-0.7-1.7-1.9
	c0-4.3,0-8.6,0-12.9c0-1.1,0.2-1.8,1.5-1.8C467,460.6,472,460.6,477.3,460.6z"/>
<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M480.9,19.1c0-5.3,0-10.1,0.1-15c0-0.5,0.9-1.5,1.4-1.5c4.5-0.1,8.9-0.1,13.4,0c0.6,0,1.6,1,1.6,1.6
	c0.1,4.5,0.1,8.9,0,13.4c0,0.5-0.8,1.4-1.3,1.4C491.1,19.1,486.2,19.1,480.9,19.1z"/>
<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M468.9,22.8c2.2,0,4.4,0,6.6,0c1.3,0,1.9,0.4,1.9,1.7c0,4.4,0,8.8,0,13.1c0,1.3-0.5,1.8-1.8,1.8
	c-4.4,0-8.8,0-13.1,0c-1.3,0-1.8-0.6-1.8-1.9c0-4.3,0-8.6,0-12.9c0-1.4,0.5-1.9,1.9-1.9C464.7,22.8,466.8,22.8,468.9,22.8z"/>
<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M2.6,497.4c0-5.3,0-10.2,0.1-15.1c0-0.5,0.9-1.3,1.4-1.3c4.5-0.1,9.1-0.1,13.6,0c0.5,0,1.4,1,1.4,1.5
	c0.1,4.4,0.1,8.8,0,13.1c0,0.6-0.9,1.6-1.3,1.6C12.8,497.5,7.9,497.4,2.6,497.4z"/>
<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M2.6,18.9c0-5.1,0-10,0.1-14.9c0-0.5,0.8-1.4,1.3-1.4c4.6-0.1,9.2-0.1,13.8,0c0.4,0,1.2,0.8,1.3,1.3
	c0.1,5,0.1,10,0.1,15.1C13.5,18.9,8.2,18.9,2.6,18.9z"/>
<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M481,497.4c0-5.6,0-10.8,0-16.4c3.7,0,7.2,0,10.8,0c5.7,0,5.7,0,5.7,5.7c0,3,0.1,6-0.1,8.9
	c0,0.6-0.8,1.8-1.2,1.8C491.2,497.5,486.2,497.4,481,497.4z"/>
    </g>
            </svg>
        </div>
    )
}